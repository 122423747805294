


















































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
@Component({
	name: "home"
})
export default class Home extends Vue {
	private bannerList = [
		{
			name: "免费工具",
			dec:
				"智慧消防管理工具， 智慧消防运营管理平台，智慧消防客服系统，免费申请使用",
			img: require("../assets/images/banner_icon.png"),
			bgImg: require("../assets/images/banner.png"),
			btnTxt: "申请使用"
		},
		{
			name: "免费工具",
			dec:
				"智慧消防管理工具， 智慧消防运营管理平台，智慧消防客服系统，免费申请使用",
			img: require("../assets/images/banner_icon.png"),
			bgImg: require("../assets/images/banner.png"),
			btnTxt: "申请使用"
		}
	];

	private list = [
		{
			img: require("../assets/images/index_icon2.png"),
			name: "免费工具",
			val: "智慧消防管理工具,全平台免费",
			url: "/apply/solution"
		},
		{
			img: require("../assets/images/index_icon1.png"),
			name: "服务订阅",
			val: "100+定制化数据服务，按需订阅付费",
			url: "/apply/access"
		},
		{
			img: require("../assets/images/index_icon3.png"),
			name: "硬件超市",
			val: "100+潜在客户曝光，一键服务接入",
			url: "/apply/product"
		},
		{
			img: require("../assets/images/index_icon5.png"),
			name: "行业赋能",
			val: "误报消除算法，AI辅助决策，全方面开放API",
			url: "/apply/industry"
		},
		{
			img: require("../assets/images/index_icon4.png"),
			name: "合作名录",
			val: "100+合作服务企业",
			url: "/apply/partner"
		}
	];

	private goTo(url: string) {
		this.$router.push({
			path: url
		});
	}

	private setActiveName(name: string) {
		console.log(name);
		AppModule.setActiveNac(name);
	}
}
